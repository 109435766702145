<template>
  <iframe :src="dashboardURL" frameborder="0" class="w-100 vh-100"></iframe>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    route: String,
  },
  computed: {
    ...mapGetters(['access_token']),
    dashboardURL() {
      return `${this.route}?jwt=${this.access_token}`;
    },
  },
};
</script>
